const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    '& h6': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }
  },
  videoModalPaper:{
    overflow:'hidden',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover':{
      backgroundColor:'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(-1),
      top: theme.spacing(-1),
    },
  },
  content: {
    padding: 0,
    position: 'relative',
    overflow:'hidden',
  },
  reactWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    marginBottom: '-1px',
    '& iframe': {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    }
  },
  reactPlayer: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
})
export default styles
