import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import CustomLink from "../../components/CustomLink";
import VideoModal from "../../components/VideoModal";
import {
  structuredTextPropTypes,
  imagePropTypes,
} from "../../utils/prop-types";
import { motion } from "framer-motion";
import {
  vPulseIn,
  vScaleFade,
  vSlideLeftFade,
  vSlideRightFade,
} from "../../utils/animations";

const HomepageHero = (props) => {
  const { slice, classes } = props;
  const { primary } = slice;
  const [openModal, setOpenModal] = useState(false);

  const styles = {};
  if (primary.backgroundImage) {
    styles.backgroundImage = `url(${primary.backgroundImage.url})`;
  }

  const openVideoDialog = () => {
    setOpenModal(true);
  };

  const closeVideoDialog = () => {
    setOpenModal(false);
  };

  const ref = useRef(null);
  const viewport = {
    once: true,
    margin: "0%",
  };

  return (
    <div className={classes.background} style={styles}>
      <div className={classes.backgroundOverlay}>
        <Grid
          ref={ref}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <motion.div
            variants={vScaleFade}
            initial={"offscreen"}
            animate={"onscreen"}
            viewport={viewport}
            style={{
              position: "relative",
              zIndex: 10,
            }}
          >
            <Typography
              variant="h6"
              component={"p"}
              className={classes.preTitle}
            >
              {RichText.asText(primary.preTitle)}
            </Typography>
          </motion.div>
          <motion.div
            variants={vSlideLeftFade}
            initial={"offscreen"}
            animate={"onscreen"}
            viewport={viewport}
          >
            <Typography variant="h1" className={classes.heroTitle}>
              {RichText.asText(primary.title1)}
            </Typography>
          </motion.div>
          <motion.div
            variants={vSlideRightFade}
            initial={"offscreen"}
            animate={"onscreen"}
            viewport={viewport}
          >
            <Typography
              variant="h1"
              component={"h2"}
              className={classes.heroSubTitle}
            >
              {RichText.asText(primary.title2)}
            </Typography>
          </motion.div>
          <div role={"list"} className={classes.pointsWrapper}>
            {primary.point1 && (
              <div role={"listitem"}>
                <motion.div
                  variants={vScaleFade}
                  initial={"offscreen"}
                  animate={"onscreen"}
                  viewport={viewport}
                >
                  <Typography
                    variant="subtitle1"
                    component={"div"}
                    className={classes.pointText}
                  >
                    {RichText.asText(primary.point1)}
                  </Typography>
                </motion.div>
              </div>
            )}
            {primary.point2 && (
              <div role={"listitem"}>
                <motion.div
                  variants={vScaleFade}
                  initial={"offscreen"}
                  animate={"onscreen"}
                  viewport={viewport}
                >
                  <Typography
                    variant="subtitle1"
                    component={"div"}
                    className={classes.pointText}
                  >
                    {RichText.asText(primary.point2)}
                  </Typography>
                </motion.div>
              </div>
            )}
            {primary.point3 && (
              <div role={"listitem"}>
                <motion.div
                  variants={vScaleFade}
                  initial={"offscreen"}
                  animate={"onscreen"}
                  viewport={viewport}
                >
                  <Typography
                    variant="subtitle1"
                    component={"div"}
                    className={classes.pointText}
                  >
                    {RichText.asText(primary.point3)}
                  </Typography>
                </motion.div>
              </div>
            )}
          </div>
          <div className={classes.heroButton}>
            <CustomLink link={primary.primaryCtaButtonLink}>
              <motion.div
                variants={vPulseIn}
                initial={"offscreen"}
                animate={"onscreen"}
                viewport={viewport}
              >
                <Button variant="contained" color="primary">
                  {RichText.asText(primary.primaryCtaButtonText)}
                </Button>
              </motion.div>
            </CustomLink>
            {primary.secondaryButtonType ? (
              <motion.div
                variants={vPulseIn}
                initial={"offscreen"}
                animate={"onscreen"}
                viewport={viewport}
              >
                <Button onClick={openVideoDialog} variant="outlined">
                  {RichText.asText(primary.secondaryCtaButtonText)}
                </Button>
              </motion.div>
            ) : (
              <CustomLink link={primary.primaryCtaButtonLink}>
                <motion.div
                  variants={vPulseIn}
                  initial={"offscreen"}
                  animate={"onscreen"}
                  viewport={viewport}
                >
                  <Button variant="outlined">
                    {RichText.asText(primary.secondaryCtaButtonText)}
                  </Button>
                </motion.div>
              </CustomLink>
            )}
          </div>
        </Grid>
      </div>
      <VideoModal
        open={openModal}
        handleClose={closeVideoDialog}
        video={primary.vimeoLink}
      />
    </div>
  );
};

HomepageHero.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      preTitle: structuredTextPropTypes,
      title1: structuredTextPropTypes,
      title2: structuredTextPropTypes,
      point1: structuredTextPropTypes,
      point2: structuredTextPropTypes,
      point3: structuredTextPropTypes,
      backgroundImage: imagePropTypes,
      primaryCtaButtonLink: PropTypes.objectOf(PropTypes.any),
      primaryCtaButtonText: structuredTextPropTypes,
      secondaryCtaButtonText: structuredTextPropTypes,
      secondaryCtaButtonLink: PropTypes.objectOf(PropTypes.any),
      vimeoLink: PropTypes.objectOf(PropTypes.any),
      secondaryButtonType: PropTypes.bool,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(HomepageHero);
